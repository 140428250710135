import React, { FC, useState } from 'react';
import { Modal } from '../../../../components/modal';
import Textarea from '../../../../components/textarea';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import { useCreateNotifications } from '../../../../services/notifications';
import { useToast } from '../../../../context/toast';
import {
  CreateNotification,
  NotificationTypeEnum,
} from '../../../../interface/notifications';
import useForm from '../../../../utils/useForm';
import FileUpload from '../../../../components/fileUpload';
import { capitalized } from '../../../../utils/capitalize';
import Dropdown from '../../../../components/dropdown';
import Switch from '../../../../components/switch';

interface IModalProps {
  refetch: () => void;
  openModal: boolean;
  setOpenModal: (v: boolean) => void;
}

const AddNotificationModal: FC<IModalProps> = ({
  refetch,
  openModal,
  setOpenModal,
}) => {
  const { mutate, isLoading } = useCreateNotifications();
  const [body, setBody] = useState<string>('');
  const [upload, setUpload] = useState<File>();
  const [type, setType] = useState<string>('');
  const [active, setActive] = useState<boolean>(false);
  const [addUrl, setAddUrl] = useState<boolean>(false);
  const { addToast } = useToast();

  const notificationType = Object.entries(NotificationTypeEnum).map(
    ([label, value]) => ({
      label: capitalized(label),
      value,
    })
  );

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUpload(e.target.files[0]);
    }
  };

  const submit = () => {
    if (!inputs.title) {
      addToast({ msg: 'Please add a title', type: 'error' });
    }
    if (addUrl && !inputs.url) {
      addToast({ msg: 'Please add a url', type: 'error' });
    } else {
      const formdata = new FormData();
      formdata.append('title', inputs.title);
      formdata.append('body', body);
      formdata.append('type', type);
      formdata.append('active', active ? 'true' : 'false');

      if (type !== NotificationTypeEnum.INFO) {
        formdata.append('image', upload as File);
      }

      if (addUrl) {
        formdata.append('url', inputs.url!);
        formdata.append('webUrl', inputs.webUrl!);
        formdata.append('cta', inputs.cta! as string);
      }

      mutate(formdata, {
        onSuccess: () => {
          setOpenModal(false);
          addToast({ msg: 'Notification Added', type: 'success' });
          clearFields();
          refetch();
        },
      });
    }
  };

  const { handleChange, handleSubmit, inputs, setInputs } =
    useForm<CreateNotification>({} as CreateNotification, submit);

  const clearFields = () => {
    setInputs({} as CreateNotification);
    setActive(false);
    setBody('');
    setType('');
    setAddUrl(false);
    setUpload(undefined);
  };

  return (
    <Modal
      padding={false}
      isVisible={openModal}
      center
      width="medium"
      close={() => {
        setOpenModal(false);
        clearFields();
      }}
      title={'Add Notification'}
      header={true}
      showFooter
      footer={
        <div className="flex justify-end gap-2">
          <Button loading={isLoading} disabled={isLoading} onClick={submit}>
            Save
          </Button>
        </div>
      }
    >
      <div className="py-4">
        <div className="flex justify-between py-3 px-4">
          <h6>Active</h6>
          <Switch checked={active} onCheck={() => setActive(!active)} />
        </div>
        <div className="flex justify-between py-3 px-4">
          <h6>Add Url</h6>
          <Switch checked={addUrl} onCheck={() => setAddUrl(!addUrl)} />
        </div>
        <div className="flex flex-col gap-2 py-2 px-4">
          <Dropdown
            label="Type"
            option={notificationType}
            onChange={(e) => setType(e?.value)}
          />
        </div>

        <div className="flex flex-col gap-2 py-2 px-4">
          <small>Title</small>
          <Input name="title" type="text" onChange={handleChange} />
        </div>

        {addUrl && (
          <>
            <div className="flex flex-col gap-2 py-2 px-4">
              <small>Url</small>
              <Input name="url" type="text" onChange={handleChange} />
            </div>
            <div className="flex flex-col gap-2 py-2 px-4">
              <small>Web Url</small>
              <Input name="webUrl" type="text" onChange={handleChange} />
            </div>
            <div className="flex flex-col gap-2 py-2 px-4">
              <small>Button title</small>
              <Input name="cta" type="text" onChange={handleChange} />
            </div>
          </>
        )}
        <div className="flex flex-col gap-2 py-2 px-4">
          <small>Body</small>
          <Textarea onChange={(e) => setBody(e.target.value)} />
        </div>
        {type !== NotificationTypeEnum.INFO && (
          <div className="flex flex-col gap-2 py-2 px-4">
            <FileUpload onSelectFile={onSelectFile} uploadFile={upload} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddNotificationModal;
