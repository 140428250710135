import { useEffect, useState } from 'react';
import { useGetTotalCardFundingSummary } from '../../../services/dashboard';
import ChartDropDown from '../../chartDropDown';
import { BarChart2, ChartDataPropNum } from '../barChart2';
import { totalSettlement } from '../../../utils/totalSettlement';
import { isCurrency } from '../../../utils/isCurrency';

const CardFundingChart = () => {
  const [openDropDown, setopenDropDown] = useState<boolean>(false);
  const [period, setPeriod] = useState('This month');
  const newPeriod =
    period === 'This month' ? 'month' : period === 'This year' ? 'year' : '';

  const [range, setRange] = useState<{ startDate: string; endDate: string }>();
  const [amount, setAmount] = useState<ChartDataPropNum>(
    {} as ChartDataPropNum
  );

  const { data } = useGetTotalCardFundingSummary({
    ...(period === 'Range' ? { ...range } : {}),
    ...(period !== 'Range' ? { period: newPeriod } : {}),
  });

  const formatData = () => {
    const newAmount: ChartDataPropNum = {};

    for (const key in data?.data.data) {
      newAmount[String(key)] = data?.data.data[key].amountInDollar;
    }
    setAmount(newAmount);
  };

  const barChartData = {
    labels: [],
    datasets: [
      {
        label: 'Amount In Dollars',
        data: amount,
        backgroundColor: '#ff6384',
      },
    ],
  };

  useEffect(() => {
    if (data) {
      formatData();
    }
  }, [data, period]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md h-fit w-full">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">
          Card Funding
          <span className="font-normal text-xs">
            ($
            {isCurrency(totalSettlement(amount) ?? 0)})
          </span>
        </h3>
        <div className="flex items-center gap-5">
          <ChartDropDown
            open={openDropDown}
            setOpen={setopenDropDown}
            value={period}
            setValue={setPeriod}
            setRange={setRange}
            data={periodArray}
            range={range!}
          />
        </div>
      </div>
      <div className="h-[305px] w-full cheapDataChart">
        <BarChart2 data={barChartData} />
      </div>
    </div>
  );
};

export default CardFundingChart;

const periodArray = [
  {
    label: 'Range',
  },
  {
    label: 'This month',
  },
  {
    label: 'This year',
  },
];
