import React, { useEffect, useState } from 'react';
import {
  IPromoData,
  ISettingsResponse,
} from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import Switch from '../../../../../components/switch';
import { useToast } from '../../../../../context/toast';
import ItemCard from '../../../../../components/itemCard';
import { format } from 'date-fns';
import Button from '../../../../../components/button';
import { Modal } from '../../../../../components/modal';
import Input from '../../../../../components/input';
import Dropdown from '../../../../../components/dropdown';
import { capitalized } from '../../../../../utils/capitalize';
import { Close } from '../../../../../components/icons';

interface IProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const PromoData = ({ settingsData, refetch }: IProps) => {
  const promoDataResponse = settingsData?.promoData;
  const [payload, setPayload] = useState<IPromoData>();
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useUpdateSettingsData();
  const [selectedDays, setSelectedDays] =
    useState<{ label: string; value: number }[]>();
  const { addToast } = useToast();

  const togglePromoData = () => {
    mutate(
      {
        promoData: {
          ...promoDataResponse,
          active: !promoDataResponse?.active,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const submit = () => {
    if (payload?.frequency === 'WEEKLY' && selectedDays?.length === 0) {
      addToast({ msg: 'Please select days for promo', type: 'error' });
      return;
    }

    let daysValues: number[] = [];

    if (payload?.frequency === 'WEEKLY') {
      daysValues = selectedDays ? selectedDays.map((day) => day.value) : [];
    }

    const updatedPayload = {
      ...payload,
      days: daysValues,
    };

    mutate(
      {
        promoData: {
          ...promoDataResponse,
          ...updatedPayload,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
          setOpen(false);
        },
      }
    );
  };

  const handleDeleteItem = (item: { label: string; value: number }) => {
    setSelectedDays(
      selectedDays?.filter((selectedDay) => selectedDay.value !== item.value)
    );
    // Update payload days
    setPayload({
      ...payload!,
      days: selectedDays!
        .filter((selectedDay) => selectedDay.value !== item.value)
        .map((day) => day.value),
    });
  };

  const handleAddService = (item: { label: string; value: number }) => {
    if (selectedDays?.some((selectedDay) => selectedDay.value === item.value)) {
      // Item already exists, do nothing
      return;
    } else {
      const updatedSelectedDays = [...(selectedDays || []), item];
      setSelectedDays(updatedSelectedDays);
      // Update payload days
      setPayload({
        ...payload!,
        days: updatedSelectedDays.map((day) => day.value),
      });
    }
  };

  useEffect(() => {
    if (settingsData || open) {
      setPayload(promoDataResponse || ({} as IPromoData));

      if (promoDataResponse?.frequency === 'WEEKLY' && promoDataResponse.days) {
        const selectedDaysFromResponse = promoDataResponse.days
          .map((dayValue) => {
            const day = daysOfWeekOptions.find(
              (option) => option.value === dayValue
            );
            return day;
          })
          .filter(Boolean);
        setSelectedDays(
          selectedDaysFromResponse as { label: string; value: number }[]
        );
      } else {
        setSelectedDays([]);
      }
    }
  }, [settingsData, open]);

  useEffect(() => {
    if (open) {
      if (payload?.frequency === 'DAILY') {
        setSelectedDays([]);
        setPayload({ ...payload, days: [] });
      } else if (payload?.frequency === 'WEEKLY' && payload.days) {
        const selectedDaysFromPayload = payload.days
          .map((dayValue) => {
            const day = daysOfWeekOptions.find(
              (option) => option.value === dayValue
            );
            return day;
          })
          .filter(Boolean);
        setSelectedDays(
          selectedDaysFromPayload as { label: string; value: number }[]
        );
      }
    }
  }, [payload?.frequency, open]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Activate Promo Data</h5>
        <Switch
          checked={promoDataResponse?.active!}
          onCheck={togglePromoData}
          disabled={isLoading}
        />
      </div>

      <div className="rounded-md bg-dark">
        <ItemCard
          title="Frequency"
          hint={promoDataResponse?.frequency}
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="Max purchase per user"
          hint={promoDataResponse?.max_purchase_per_user}
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="Total available for purchase"
          hint={promoDataResponse?.total_available_for_purchase}
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="Total Purchased"
          hint={promoDataResponse?.total_purchased}
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="Start Date"
          hint={
            promoDataResponse?.start_date &&
            format(new Date(promoDataResponse.start_date), 'MMM do, yyyy')
          }
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="End Date"
          hint={
            promoDataResponse?.end_date &&
            format(new Date(promoDataResponse.end_date), 'MMM do, yyyy')
          }
          showIcon={false}
        />
        <hr className="border-grey" />{' '}
        <ItemCard
          title="Start time"
          hint={promoDataResponse?.start_time}
          showIcon={false}
        />
        <hr className="border-grey" />{' '}
        <ItemCard
          title="End time"
          hint={promoDataResponse?.end_time}
          showIcon={false}
        />
        {promoDataResponse?.frequency === 'WEEKLY' && (
          <>
            <hr className="border-grey" />
            <ItemCard
              title="Days"
              hint={promoDataResponse?.days.map((item) => item).join(', ')}
              showIcon={false}
            />
          </>
        )}
        <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
          <Button onClick={() => setOpen(true)}>Edit Promo Data</Button>
        </div>
      </div>

      <Modal
        isVisible={open}
        close={setOpen}
        title={'Promo Data'}
        padding={false}
        width="medium"
        center
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div>
          <div className="flex justify-between items-center py-4 px-4">
            <h5>Frequency</h5>
            <div className="w-2/5">
              <Dropdown
                label="Frequency"
                option={[
                  { label: 'Daily', value: 'DAILY' },
                  { label: 'Weekly', value: 'WEEKLY' },
                ]}
                onChange={(e) =>
                  setPayload({ ...payload!, frequency: e?.value })
                }
                defaultValue={payload?.frequency}
              />
            </div>
          </div>

          {payload?.frequency === 'WEEKLY' && (
            <div className="flex justify-between items-center py-4 px-4">
              <h5>Days</h5>
              <div className="w-2/5">
                <Dropdown
                  label="Days"
                  option={daysOfWeekOptions}
                  onChange={(e) => handleAddService(e!)}
                />
                <div className="flex mt-2 gap-2 justify-end">
                  {selectedDays?.map(
                    (item: { label: string; value: number }, index: number) => (
                      <div
                        className="p-2 flex items-center relative border border-gray rounded-[50px]"
                        key={index}
                      >
                        <div>{capitalized(item.label)}</div>
                        <div
                          className="absolute top-[-6px] right-[1px] border border-gray w-[15px] flex items-center justify-center h-[15px] rounded-[50px] cursor-pointer"
                          onClick={() => handleDeleteItem(item)}
                        >
                          <Close className="w-[9px] h-[9px]" />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Maximum Purchase Per User</h5>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setPayload({
                    ...payload!,
                    max_purchase_per_user: Number(e.target.value),
                  })
                }
                defaultValue={payload?.max_purchase_per_user}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Total available for Purchase</h5>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setPayload({
                    ...payload!,
                    total_available_for_purchase: Number(e.target.value),
                  })
                }
                defaultValue={payload?.total_available_for_purchase}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Total Purchased</h5>
            <div className="w-2/5">
              <Input
                type="text"
                disabled
                value={payload?.total_purchased}
                className="text-xs"
                trailing={
                  <p
                    className="cursor-pointer text-sm text-blue"
                    onClick={() =>
                      setPayload({
                        ...payload!,
                        total_purchased: Number(0),
                      })
                    }
                  >
                    Reset
                  </p>
                }
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Start Date</h5>
            <div className="w-2/5">
              <Input
                type="date"
                onChange={(e) =>
                  setPayload({ ...payload!, start_date: e.target.value })
                }
                value={payload?.start_date}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>End Date</h5>
            <div className="w-2/5">
              <Input
                type="date"
                onChange={(e) =>
                  setPayload({ ...payload!, end_date: e.target.value })
                }
                value={payload?.end_date}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Start Time</h5>
            <div className="w-2/5">
              <Input
                type="time"
                onChange={(e) =>
                  setPayload({ ...payload!, start_time: e.target.value })
                }
                defaultValue={payload?.start_time}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>End Time</h5>
            <div className="w-2/5">
              <Input
                type="time"
                onChange={(e) =>
                  setPayload({ ...payload!, end_time: e.target.value })
                }
                defaultValue={payload?.end_time}
                className="text-xs"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PromoData;

const daysOfWeekOptions = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 },
];
