import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import FileUpload from '../../../../components/fileUpload';
import Input from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import Switch from '../../../../components/switch';
import Textarea from '../../../../components/textarea';
import { useToast } from '../../../../context/toast';
import {
  NotificationTypeEnum,
  CreateNotification,
  NotificationResponse,
} from '../../../../interface/notifications';
import {
  useCreateNotifications,
  useEditNotifications,
} from '../../../../services/notifications';
import { capitalized } from '../../../../utils/capitalize';
import useForm from '../../../../utils/useForm';

interface EditNotification {
  editItem: NotificationResponse;
  refetch: () => void;
}

const EditNotificaton = ({ editItem, refetch }: EditNotification) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useEditNotifications();
  const [body, setBody] = useState<string>(editItem.body);
  const [upload, setUpload] = useState<File>();
  const [type, setType] = useState<string>(editItem.type);
  const [active, setActive] = useState<boolean>(editItem.active);
  const [addUrl, setAddUrl] = useState<boolean>(false);

  const { addToast } = useToast();

  const notificationType = Object.entries(NotificationTypeEnum).map(
    ([label, value]) => ({
      label: capitalized(label),
      value,
    })
  );

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUpload(e.target.files[0]);
    }
  };

  const submit = () => {
    if (!inputs.title) {
      addToast({ msg: 'Please add a title', type: 'error' });
    }
    if (addUrl && !inputs.url) {
      addToast({ msg: 'Please add a url', type: 'error' });
    } else {
      const formdata = new FormData();
      formdata.append('title', inputs.title);
      formdata.append('body', body);
      formdata.append('type', type);
      formdata.append('active', active ? 'true' : 'false');

      if (type !== NotificationTypeEnum.INFO && upload) {
        formdata.append('image', upload as File);
      }

      if (addUrl) {
        formdata.append('url', inputs.url!);
        formdata.append('webUrl', inputs.webUrl!);
        formdata.append('cta', inputs.cta! as string);
      }

      if (!addUrl) {
        formdata.append('url', '');
        formdata.append('cta', '');
        formdata.append('webUrl', '');
      }

      mutate(
        { id: editItem._id, payload: formdata },
        {
          onSuccess: () => {
            setOpen(false);
            clearFields();
            addToast({ msg: 'Notification Updated', type: 'success' });
            refetch();
          },
        }
      );
    }
  };

  const { handleChange, handleSubmit, inputs, setInputs } =
    useForm<CreateNotification>(editItem, submit);

  const clearFields = () => {
    setInputs({} as CreateNotification);
    setActive(false);
    setBody('');
    setType('');
    setAddUrl(false);
    setUpload(undefined);
  };

  useEffect(() => {
    if (editItem && open) {
      setBody(editItem.body);
      setInputs(editItem);
      setType(editItem.type);
      setActive(editItem.active);
      if (editItem.url) {
        setAddUrl(true);
      }
    }
  }, [editItem, open]);

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          Edit
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        width="medium"
        close={() => {
          setOpen(false);
          clearFields();
        }}
        title={'Update Notification'}
        header={true}
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Save
            </Button>
          </div>
        }
      >
        <div className="py-4">
          <div className="flex justify-between py-3 px-4">
            <h6>Active</h6>
            <Switch checked={active} onCheck={() => setActive(!active)} />
          </div>
          <div className="flex justify-between py-3 px-4">
            <h6>Add Url</h6>
            <Switch checked={addUrl} onCheck={() => setAddUrl(!addUrl)} />
          </div>
          <div className="flex flex-col gap-2 py-2 px-4">
            <Dropdown
              label="Type"
              option={notificationType}
              onChange={(e) => setType(e?.value)}
              defaultValue={type}
            />
          </div>
          <div className="flex flex-col gap-2 py-2 px-4">
            <small>Title</small>
            <Input
              name="title"
              type="text"
              onChange={handleChange}
              defaultValue={inputs.title}
            />
          </div>
          {addUrl && (
            <>
              <div className="flex flex-col gap-2 py-2 px-4">
                <small>Url</small>
                <Input
                  name="url"
                  type="text"
                  onChange={handleChange}
                  defaultValue={inputs.url}
                />
              </div>
              <div className="flex flex-col gap-2 py-2 px-4">
                <small>Web Url</small>
                <Input
                  name="webUrl"
                  type="text"
                  onChange={handleChange}
                  defaultValue={inputs.webUrl}
                />
              </div>
              <div className="flex flex-col gap-2 py-2 px-4">
                <small>Button title</small>
                <Input
                  name="cta"
                  type="text"
                  onChange={handleChange}
                  defaultValue={inputs.cta}
                />
              </div>
            </>
          )}
          <div className="flex flex-col gap-2 py-2 px-4">
            <small>Body</small>
            <Textarea
              onChange={(e) => setBody(e.target.value)}
              defaultValue={body}
            />
          </div>
          {type !== NotificationTypeEnum.INFO && (
            <div className="flex flex-col gap-2 py-2 px-4">
              <FileUpload
                onSelectFile={onSelectFile}
                uploadFile={upload}
                defaultImage={editItem.photo}
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default EditNotificaton;
