import { useEffect, useState } from 'react';
import { Modal } from '../../../../../components/modal';
import Switch from '../../../../../components/switch';
import {
  ISettingsResponse,
  ISubChild,
  ISubSubChild,
} from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import { capitalized } from '../../../../../utils/capitalize';
import { useToast } from '../../../../../context/toast';
import Charges from '../pay-bills/charge';
import { Close } from '../../../../../components/icons';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';

interface IBetting {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const Betting = ({ settingsData, refetch }: IBetting) => {
  const betting = settingsData?.isp.betting;
  const { mutate, isLoading } = useUpdateSettingsData();
  const [toggleItems, setToggleItems] = useState<ISubChild[]>(
    betting?.data ?? []
  );
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<ISubChild>({} as ISubChild);
  const [charge, setCharge] = useState<string>(
    betting?.charge.toString() ?? ''
  );
  const [openAddNewModal, setOpenAddNewModal] = useState(false);
  const [addNewItemPayload, setAddNewItemPayload] = useState<ISubChild>({
    name: '',
    visibility: false,
    provider: [
      {
        name: 'sage',
        active: false,
        rate: 0.1,
      },
      {
        name: 'kuda',
        active: true,
        rate: 0.1,
      },
    ],
  });

  const { addToast } = useToast();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: 'Successfully Updated', type: 'success' });
  };

  const toggleChange = () => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          betting: {
            ...betting,
            active: !settingsData?.isp.betting.active,
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const submitCharges = () => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          betting: {
            ...betting,
            charge: parseInt(charge),
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleBettingVisibility = (selected: ISubChild) => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          betting: {
            ...betting,
            data: betting?.data.map((item) =>
              item.name === selected.name
                ? { ...selected, visibility: !selected.visibility }
                : item
            ),
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const updateBettingProvider = () => {
    if (!betting?.data || !settingsData?.isp || !editItem?.name) return;

    mutate(
      {
        isp: {
          ...settingsData.isp,
          betting: {
            ...betting,
            data: betting.data.map((item) =>
              item.name === editItem.name ? editItem : item
            ),
          },
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpen(false);
        },
      }
    );
  };

  const addBetting = () => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          betting: {
            ...betting,
            data: [...betting?.data!, addNewItemPayload],
          },
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpenAddNewModal(false);
        },
      }
    );
  };

  const deleteBetting = (item: number) => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          betting: {
            ...betting,
            data: betting?.data.filter((_, itemIndex) => itemIndex !== item),
          },
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpenAddNewModal(false);
        },
      }
    );
  };

  useEffect(() => {
    setToggleItems(betting?.data || []);
  }, [betting]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Activate Betting</h5>
        <Switch
          checked={betting?.active!}
          onCheck={toggleChange}
          disabled={isLoading}
        />
      </div>

      <div className="rounded-md bg-dark mb-6">
        <Charges
          setCharge={setCharge}
          charge={charge}
          submit={submitCharges}
          loading={isLoading}
        />
      </div>
      {toggleItems.map((item: ISubChild, index: number) => (
        <div
          key={`${item.name} - ${index}`}
          className="grid grid-cols-[1fr_auto] gap-2 items-center"
        >
          <div className="mb-2 bg-dark px-2 py-3 rounded-md flex justify-between items-center">
            <h5>{capitalized(item.name)}</h5>
            <div className="flex items-center">
              <div
                className="mr-5 cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  setEditItem(item);
                }}
              >
                Edit
              </div>
              <span className="max-w-[150px] w-full grid place-items-center">
                <Switch
                  checked={item.visibility}
                  onCheck={() => handleBettingVisibility(item)}
                  disabled={isLoading}
                />
              </span>
            </div>
          </div>
          <Close
            className="cursor-pointer"
            onClick={() => deleteBetting(index)}
          />
        </div>
      ))}
      <div className="flex justify-end mt-4 gap-2">
        <Button
          bgColor="bg-transparent"
          className="border-blue border "
          onClick={() => setOpenAddNewModal(true)}
        >
          Add New
        </Button>
      </div>

      <Modal
        isVisible={openAddNewModal}
        close={setOpenAddNewModal}
        title={'Add New'}
        center
        header
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button
              onClick={addBetting}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div>
          <div>
            <p>Name</p>
            <Input
              defaultValue={addNewItemPayload.name}
              onChange={(e) =>
                setAddNewItemPayload({
                  ...addNewItemPayload,
                  name: e.target.value,
                })
              }
            />
          </div>
          <div className="flex justify-between items-center mt-4">
            <p>Active</p>
            <Switch
              checked={addNewItemPayload.visibility}
              onCheck={() =>
                setAddNewItemPayload({
                  ...addNewItemPayload,
                  visibility: !addNewItemPayload.visibility,
                })
              }
            />
          </div>
        </div>
      </Modal>

      {open && editItem && (
        <Modal
          isVisible={open}
          close={() => {
            setOpen(false);
            setEditItem({} as ISubChild);
          }}
          title={capitalized(editItem.name)}
          padding={false}
          center
          header
          width="medium"
          showFooter
          footer={
            <div className="flex justify-end gap-2">
              <Button
                onClick={updateBettingProvider}
                loading={isLoading}
                disabled={isLoading}
              >
                Update
              </Button>
            </div>
          }
        >
          {editItem?.provider?.map((item: ISubSubChild, index: number) => (
            <div key={index}>
              <div className="py-4 px-4 flex justify-between items-center text-xs">
                <div>{capitalized(item.name)}</div>
                <div className="flex items-center">
                  {item.rate !== null && (
                    <Input
                      leading={<h5 className="text-xs">Rate:</h5>}
                      defaultValue={item.rate}
                      className="w-[60px] !py-0"
                      onChange={(e) =>
                        setEditItem({
                          ...editItem,
                          provider: editItem.provider.map((providerItem) =>
                            providerItem.name === item.name
                              ? {
                                  ...providerItem,
                                  rate: Number(e.target.value),
                                }
                              : providerItem
                          ),
                        })
                      }
                    />
                  )}
                  <span className="max-w-[150px] w-full flex justify-end ml-5">
                    <Switch
                      checked={item.active}
                      onCheck={() =>
                        setEditItem({
                          ...editItem,
                          provider: editItem.provider.map((providerItem) =>
                            providerItem.name === item.name
                              ? {
                                  ...providerItem,
                                  active: !providerItem.active,
                                }
                              : providerItem
                          ),
                        })
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default Betting;
