import React from 'react';

interface ISwitch {
  checked: boolean;
  onCheck: () => void;
  disabled?: boolean;
}

const Switch = ({ checked, onCheck, disabled }: ISwitch) => {
  return (
    <div
      className={`flex cursor-pointer select-none items-center ${
        disabled && 'opacity-30'
      }`}
    >
      <div className="relative" onClick={() => onCheck()}>
        <input type="checkbox" className="sr-only" defaultChecked={checked} />
        <div
          className={`block h-6 w-10 rounded-full ${
            checked ? 'bg-green-700' : 'bg-ldark'
          } `}
        ></div>
        <div
          className={`dot absolute ${
            checked ? 'left-5' : 'left-1'
          } top-1 h-4 w-4 rounded-full ${
            checked ? 'bg-ldark' : 'bg-dark'
          } transition-all ease-in-out slide duration-700`}
        ></div>
      </div>
    </div>
  );
};

export default Switch;
