import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../../components/button';
import Dropdown from '../../../../../components/dropdown';
import { Modal } from '../../../../../components/modal';
import { useToast } from '../../../../../context/toast';
import {
  Campaign,
  MailCampaignStatusEnum,
  updateEmailCampaignPayload,
} from '../../../../../interface/mail-campaigns';
import { useUpdateCampaign } from '../../../../../services/mail-campaigns';
import { capitalized } from '../../../../../utils/capitalize';

interface EditCampaignProps {
  campaign: Campaign;
  refetch: () => void;
}

const EditCampaigns: FC<EditCampaignProps> = ({ campaign, refetch }) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useUpdateCampaign();
  const [payload, setPayload] = useState<updateEmailCampaignPayload>();
  const { addToast } = useToast();

  const campaignSTatus = Object.entries(MailCampaignStatusEnum).map(
    ([label, value]) => ({
      label: capitalized(label),
      value,
    })
  );

  const submit = () => {
    mutate(payload!, {
      onSuccess: () => {
        setOpen(false);
        refetch();
        addToast({
          msg: 'Campaign status updated successfully',
          type: 'success',
        });
      },
    });
  };

  useEffect(() => {
    setPayload({ campaignId: campaign._id, status: campaign.status });
  }, [campaign]);

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          Edit
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        width="medium"
        close={() => {
          setOpen(false);
        }}
        title={'Update Campaign Status'}
        header={true}
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Save
            </Button>
          </div>
        }
      >
        <div className="py-4">
          <div className="flex flex-col gap-2 py-2 px-4">
            <Dropdown
              label="Status"
              option={campaignSTatus}
              onChange={(e) => setPayload({ ...payload!, status: e?.value })}
              defaultValue={payload?.status}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditCampaigns;
