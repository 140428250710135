import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { IToastMsg } from '../../context/toast';

export const Toast = ({ toasts }: { toasts: IToastMsg[] }) => {
  const ref = useRef<HTMLElement | null>(null);
  const [mount, setMount] = useState(false);

  useEffect(() => {
    ref.current = document.body;
    setMount(true);
  }, []);

  return mount
    ? createPortal(
        <div
          id="toast"
          className={`fixed w-full transition-all z-[999] ease-in-out duration-700 ${
            toasts.length > 0 ? 'bottom-0' : 'bottom-[-100%]'
          }`}
        >
          {toasts.map((toast, index: number) => (
            <div
              className={`p-4 text-white ${
                toast.type === 'success' ? 'bg-green-700' : 'bg-red-500'
              }`}
              key={`toast${index}`}
            >
              <div className="container">
                <p className="text-center">{toast.msg}</p>
              </div>
            </div>
          ))}
        </div>,
        ref.current!
      )
    : null;
};
