import { ReactNode, useEffect } from 'react';
import Button from '../button';
import Close from '../icons/Close';

interface IProps {
  children: ReactNode;
  isVisible: boolean;
  close: (value: boolean) => void;
  padding?: boolean;
  title?: string;
  center?: boolean;
  overlay?: boolean;
  header?: boolean;
  footer?: JSX.Element;
  showFooter?: boolean;
  width?: 'medium' | 'large';
  overflow?: boolean;
}

export const Modal = ({
  children,
  isVisible,
  close,
  padding = true,
  title,
  center,
  overlay,
  header = false,
  footer,
  showFooter,
  width,
  overflow = false,
}: IProps) => {
  useEffect(() => {
    if (!isVisible) {
      document.body.style.overflow = 'auto';
    }
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div
      className={`fixed top-0 right-0 overflow-y-auto ${
        overlay ? 'z-[9]' : 'z-[8]'
      } h-full w-full bg-black/40 flex justify-center ${
        center ? 'items-center' : ''
      }`}
    >
      <div
        className={`bg-lightGray pt-4 mx-4 relative max-w-[650px] xl:min-w-[450px] rounded-md ${
          width === 'medium' ? 'w-96' : width === 'large' ? 'w-full' : 'w-full'
        }`}
      >
        {header ? (
          <div className="flex justify-between border-b border-gray">
            {title && <div className="px-4 pb-4">{title}</div>}
            <Button
              onClick={() => {
                document.body.style.overflow = 'auto';
                close(false);
              }}
              className="px-1 py-1 right-4 top-4 absolute bg-transparent"
            >
              <Close />
            </Button>
          </div>
        ) : (
          <>
            <Button
              onClick={() => {
                document.body.style.overflow = 'auto';
                close(false);
              }}
              className="px-1 py-1 right-4 top-4 absolute bg-transparent"
            >
              <Close />
            </Button>
            {title && (
              <div className="px-4 pb-4 border-b border-grey">{title}</div>
            )}
          </>
        )}
        <div
          className={`${
            overflow ? 'overflow-y-visible' : 'overflow-y-auto'
          } max-h-[calc(100vh-250px)]  modalScrollBar rounded-b-md`}
        >
          <div className={`${padding ? 'p-4' : ''} `}>{children}</div>
        </div>
        {showFooter && (
          <div className="px-4 py-4 bg-dark rounded-b-md">{footer}</div>
        )}
      </div>
    </div>
  );
};
