import React, { useEffect, useState } from "react";
import { FullscreenModal } from "../../../../components/modal/fullscreenModal";
import Input from "../../../../components/input";
import {
  useKudaBalance,
  useKudaTxnHistory,
} from "../../../../services/transaction";
import { format } from "date-fns";
import { capitalized } from "../../../../utils/capitalize";
import { isCurrency } from "../../../../utils/isCurrency";
import { formatNumber } from "../../../../utils/formatNumbers";
import Pagination from "../../../../components/pagination";
import TransactionDetails from "./transactionDetails";
import { ArrowDown, ArrowUp } from "../../../../components/icons";
import { IPostingHistory } from "../../../../interface/txn";

interface IKudaTransactions {
  open: boolean;
  setOpen: (v: boolean) => void;
}

interface ITxnFilter {
  EndDate?: string;
  StartDate?: string;
}

const KudaTransactions = ({ open, setOpen }: IKudaTransactions) => {
  const [txnSize, setTxnSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filter, setFilter] = useState<ITxnFilter>();
  const { data, isLoading } = useKudaTxnHistory({
    PageSize: txnSize,
    PageNumber: currentPage,
    ...filter,
  });
  const { data: kudaBalance } = useKudaBalance();

  useEffect(() => {
    if (filter) setCurrentPage(1);
  }, [filter]);

  return (
    <FullscreenModal
      isVisible={open}
      close={() => {
        setOpen(false);
        setFilter(undefined);
        setCurrentPage(1);
      }}
      title="Kuda Transaction History"
      header
      loading={isLoading}
    >
      <h2 className="font-medium text-grayText pb-4">
        Balance (₦{isCurrency(kudaBalance?.data!)})
      </h2>
      <h2 className="font-medium text-xs text-grayText pb-4">
        Transaction ({formatNumber(data?.data.data.totalRecordInStore!)})
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">Start</span>}
            onChange={(e) =>
              setFilter({ ...filter, StartDate: e.target.value.trim() })
            }
          />
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">End</span>}
            onChange={(e) =>
              setFilter({ ...filter, EndDate: e.target.value.trim() })
            }
          />
        </div>
      </div>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-activeLinkBlack ">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Beneficiary Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Amount
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Reference Number
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      MetaData
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data?.data.data.postingsHistory.map(
                      (item: IPostingHistory, index: number) => (
                        <tr key={index} className={"border-b border-grey"}>
                          <td className="text-sm p-4">
                            {item.realDate &&
                              format(
                                new Date(item.realDate),
                                "MMM do, yyyy H:mma"
                              )}
                          </td>
                          <td className="text-sm p-4 flex items-center gap-2">
                            <div
                              className={`p-1 items-center justify-center flex rounded-md ${
                                item.postingRecordType === 2
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }`}
                            >
                              {item.postingRecordType === 2 ? (
                                <ArrowDown />
                              ) : (
                                <ArrowUp />
                              )}
                            </div>
                            {capitalized(item.beneficiaryName ?? "----")}
                            <span>
                              {item.referenceNumber?.charAt(0) === "R" && (
                                <div className="bg-green-500 text-white p-1 text-xs rounded-md flex">
                                  <div>Reversal</div>
                                </div>
                              )}
                            </span>
                          </td>

                          <td
                            className={`${
                              item.postingRecordType === 2
                                ? "text-green-500"
                                : ""
                            } text-sm p-4`}
                          >
                            {item.postingRecordType === 2 ? "+ " : "- "}₦
                            {isCurrency(item.amount / 100)}
                          </td>
                          <td className="text-sm p-4">
                            {item.referenceNumber}
                          </td>
                          <td className="text-sm p-4">
                            {JSON.parse(item.metaData)?.ThirdPartyReference ??
                              "----"}
                          </td>
                          <td>
                            <TransactionDetails item={item} />
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.data.data.totalRecordInStore! > 0 && (
          <Pagination
            totalItems={data?.data.data.totalRecordInStore!}
            itemsPerPage={txnSize}
            setPage={setCurrentPage}
            page={currentPage}
            setSize={setTxnSize}
          />
        )}
      </div>
    </FullscreenModal>
  );
};

export default KudaTransactions;
