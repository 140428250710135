import { format } from 'date-fns';
import { useState } from 'react';
import Button from '../../../components/button';
import Dropdown from '../../../components/dropdown';
import Pagination from '../../../components/pagination';
import {
  NotificationResponse,
  NotificationTypeEnum,
  StatusEnum,
} from '../../../interface/notifications';
import { useGetNotifications } from '../../../services/notifications';
import { capitalized } from '../../../utils/capitalize';
import useUrlPagination from '../../../utils/urlPagination';
import AddNotificationModal from './addNotificationModal';
import DeleteNotification from './deleteNotificationModal';
import EditNotificaton from './editNotificationModal';

const Notifications = () => {
  const [openNotificiationModal, setOpenNotificatonModal] =
    useState<boolean>(false);
  const [size, setSize] = useState(10);
  const [activeTab, setActiveTab] = useState<string>('info');
  const [status, setStatus] = useState<string>();

  const { currentPage, setPage } = useUrlPagination();

  const { data, refetch, isLoading } = useGetNotifications({
    page: currentPage,
    limit: size,
    type:
      activeTab === 'banner'
        ? NotificationTypeEnum.BANNER
        : activeTab === 'slide'
        ? NotificationTypeEnum.SLIDE
        : NotificationTypeEnum.INFO,
    active: status,
  });

  const statusOptions = Object.entries(StatusEnum).map(([label, value]) => ({
    label: capitalized(label),
    value,
  }));

  const tabItems = ['info', 'banner', 'slide'];

  return (
    <>
      <AddNotificationModal
        setOpenModal={setOpenNotificatonModal}
        openModal={openNotificiationModal}
        refetch={refetch}
      />
      <div className="flex justify-between pb-4">
        <h2 className="font-medium text-grayText">
          Notifications ({data?.data.pagination.total})
        </h2>
        <Button
          onClick={() => setOpenNotificatonModal(true)}
          bgColor="bg-dark "
        >
          Create notifications
        </Button>
      </div>

      <div className="grid md:grid-cols-2  lg:grid-cols-6 gap-4 mb-4">
        <Dropdown
          label="Status"
          option={statusOptions}
          onChange={(e) => setStatus(e?.value)}
        />
      </div>

      <div className="flex gap-4 text-sm mb-4 items-center">
        {tabItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setActiveTab(item);
              setPage(1);
              setStatus(undefined);
            }}
            className={`${
              activeTab === item
                ? 'text-white bg-activeLinkBlack font-medium px-3 py-[.35rem] mx-1 rounded-md'
                : 'text-grayText'
            } cursor-pointer`}
          >
            {capitalized(item)}
          </div>
        ))}
      </div>
      {activeTab === 'info' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto tableScrollBar ">
            <div className="inline-block min-w-full ">
              <div className=" overflow-x-auto ">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Title
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Url
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Body
                      </th>

                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Type
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      data?.data.response.map((item: NotificationResponse) => (
                        <tr key={item?._id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>

                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.title}
                          </td>

                          <td className="text-sm p-4 ">{item.url}</td>
                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.body}
                          </td>
                          <td className="text-sm p-4">{item.type}</td>
                          <td className="text-sm p-4">
                            <EditNotificaton
                              editItem={item}
                              refetch={refetch}
                            />
                          </td>
                          <td className="text-sm p-4">
                            <DeleteNotification
                              id={item._id}
                              refetch={refetch}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {data?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={data?.data.pagination.total!}
              itemsPerPage={size}
              setPage={setPage}
              page={currentPage}
              setSize={setSize}
            />
          )}
        </div>
      )}
      {activeTab === 'banner' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto tableScrollBar ">
            <div className="inline-block min-w-full ">
              <div className=" overflow-x-auto ">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Title
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Url
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Body
                      </th>

                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Type
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      data?.data.response.map((item: NotificationResponse) => (
                        <tr key={item?._id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>

                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.title}
                          </td>

                          <td className="text-sm p-4 ">{item.url}</td>
                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.body}
                          </td>
                          <td className="text-sm p-4">
                            <EditNotificaton
                              editItem={item}
                              refetch={refetch}
                            />
                          </td>
                          <td className="text-sm p-4">
                            <DeleteNotification
                              id={item._id}
                              refetch={refetch}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {data?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={data?.data.pagination.total!}
              itemsPerPage={size}
              setPage={setPage}
              page={currentPage}
              setSize={setSize}
            />
          )}
        </div>
      )}
      {activeTab === 'slide' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto tableScrollBar ">
            <div className="inline-block min-w-full ">
              <div className=" overflow-x-auto ">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Title
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Url
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Body
                      </th>

                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Type
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      data?.data.response.map((item: NotificationResponse) => (
                        <tr key={item?._id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>

                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.title}
                          </td>

                          <td className="text-sm p-4 ">{item.url}</td>
                          <td className="text-sm p-4 titleEllipses max-w-[20rem]">
                            {item.body}
                          </td>
                          <td className="text-sm p-4">
                            <EditNotificaton
                              editItem={item}
                              refetch={refetch}
                            />
                          </td>
                          <td className="text-sm p-4">
                            <DeleteNotification
                              id={item._id}
                              refetch={refetch}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {data?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={data?.data.pagination.total!}
              itemsPerPage={size}
              setPage={setPage}
              page={currentPage}
              setSize={setSize}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Notifications;
