import React, { FC, useState } from 'react';
import { useGetNegativeCardsBalance } from '../../../../services/cards';
import { FullscreenModal } from '../../../../components/modal/fullscreenModal';
import { NegativeCardBalanceResponse } from '../../../../interface/cards';
import { format } from 'date-fns';
import Pagination from '../../../../components/pagination';
import { isCurrency } from '../../../../utils/isCurrency';
import { capitalized } from '../../../../utils/capitalize';
import { Link } from 'react-router-dom';

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

const NegativeCardBalances: FC<IProps> = ({ open, setOpen }) => {
  const [size, setSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data, isLoading } = useGetNegativeCardsBalance({ currentPage, size });

  return (
    <FullscreenModal
      isVisible={open}
      close={() => {
        setOpen(false);
      }}
      title="Negative Card Balance"
      header
      loading={isLoading}
    >
      <h2 className="font-medium text-grayText pb-4">
        Number of Cards ({data?.data.pagination.total})
      </h2>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-activeLinkBlack ">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Card Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Currency
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Balance
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Variant
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Brand
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Customer
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data?.data.response.map(
                      (item: NegativeCardBalanceResponse, index: number) => (
                        <tr key={item._id} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>
                          <td className="text-sm p-4">{item?.name}</td>
                          <td className="text-sm p-4">{item?.currency}</td>
                          <td className="text-sm p-4">
                            ${isCurrency(item?.balance)}
                          </td>
                          <td className="text-sm p-4">
                            {capitalized(item?.variant)}
                          </td>
                          <td className="text-sm p-4">{item?.brand}</td>
                          <td className="text-sm p-4">
                            {capitalized(item?.status)}
                          </td>
                          <td className="text-sm flex gap-2 items-center p-4">
                            <Link
                              className="text-blue"
                              to={`/app/user/${item.user}`}
                            >
                              {item.cardName}
                            </Link>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.data.pagination.total! > 0 && (
          <Pagination
            totalItems={data?.data.pagination.total!}
            itemsPerPage={size}
            setPage={setCurrentPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </FullscreenModal>
  );
};

export default NegativeCardBalances;
