import React, { useState } from "react";
import {
  useGetAyinlakData,
  useUpdateAyinlakData,
} from "../../../../../../services/settings";
import Button from "../../../../../../components/button";
import Input from "../../../../../../components/input";
import ItemCard from "../../../../../../components/itemCard";
import { Modal } from "../../../../../../components/modal";
import {
  IProviderData,
  IProviders,
} from "../../../../../../interface/settings";
import { useToast } from "../../../../../../context/toast";
import { Close } from "../../../../../../components/icons";
import Switch from "../../../../../../components/switch";

const AyinlakProvider = () => {
  const { data, refetch } = useGetAyinlakData("ayinlakData");
  const { mutate, isLoading } = useUpdateAyinlakData();
  const [editItem, setEditItem] = useState<IProviderData[]>([]);
  const [title, setTitle] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const { addToast } = useToast();

  const handleInputsChange = (
    value: string | boolean,
    inputName: string,
    index: number
  ) => {
    setEditItem((prevState) => {
      const updatedInputs: IProviderData[] = [...prevState];
      if (inputName === "name") {
        updatedInputs[index].name = value as string;
      } else if (inputName === "id") {
        updatedInputs[index].id = parseInt(value as string);
      } else if (inputName === "price") {
        updatedInputs[index].price = parseInt(value as string);
      } else if (inputName === "telcoPrice") {
        updatedInputs[index].telco_price = parseInt(value as string);
      } else if (inputName === "is_promo") {
        updatedInputs[index].is_promo = value as boolean;
      } else if (inputName === "disabled") {
        updatedInputs[index].disabled = value as boolean;
      }
      return updatedInputs;
    });
  };

  const submit = () => {
    let updatedData = { ...(data?.data ?? ({} as IProviders)) };

    if (title === "mtn") {
      updatedData[1] = editItem;
    } else if (title === "glo") {
      updatedData[2] = editItem;
    } else if (title === "9mobile") {
      updatedData[3] = editItem;
    } else if (title === "airtel") {
      updatedData[4] = editItem;
    }
    mutate(
      { provider: "ayinlakData", data: updatedData },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const addCharges = () => {
    const newCharges: IProviderData = {
      id: 0,
      name: "",
      price: 0,
      telco_price: 0,
      is_promo: false,
      disabled: false,
    };
    setEditItem([...editItem, newCharges]);
  };

  const deleteProvider = (itemIndex: number) => {
    setEditItem(editItem.filter((_, index) => index !== itemIndex));
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="rounded-md bg-dark">
        <div
          onClick={() => {
            setEditItem(data?.data[1]!);
            setOpen(true);
            setTitle("mtn");
          }}
        >
          <ItemCard title="MTN" />
        </div>
        <hr className="border-grey" />
        <div
          onClick={() => {
            setEditItem(data?.data[4]!);
            setOpen(true);
            setTitle("airtel");
          }}
        >
          <ItemCard title="Airtel" />
        </div>
        <hr className="border-grey" />
        <div
          onClick={() => {
            setEditItem(data?.data[3]!);
            setOpen(true);
            setTitle("9mobile");
          }}
        >
          <ItemCard title="9mobile" />
        </div>
        <hr className="border-grey" />
        <div
          onClick={() => {
            setEditItem(data?.data[2]!);
            setOpen(true);
            setTitle("glo");
          }}
        >
          <ItemCard title="Glo" />
        </div>
      </div>

      {editItem && open && (
        <Modal
          isVisible={open}
          close={setOpen}
          title={title}
          padding={false}
          center
          width="large"
          header
          showFooter
          footer={
            <div className="flex justify-end gap-2">
              <Button
                bgColor="bg-transparent"
                className="border-blue border "
                onClick={addCharges}
              >
                Add
              </Button>
              <Button onClick={submit} loading={isLoading} disabled={isLoading}>
                Save
              </Button>
            </div>
          }
        >
          <div className="px-4">
            <div className="grid py-2 mb-2 grid-cols-[1fr_1fr_1fr_1fr_auto_auto_auto] gap-1">
              <h5>ID</h5>
              <h5>Name</h5>
              <h5>Price</h5>
              <h5>Telco Price</h5>
              <h5>Promo</h5>
              <h5>Disabled</h5>
            </div>
            {editItem.map((item: IProviderData, index) => (
              <div
                className="grid grid-cols-[1fr_1fr_1fr_1fr_auto_auto_auto] items-center gap-1 mb-1"
                key={`${item.name}-${item.id}`}
              >
                <Input
                  defaultValue={item.id}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, "id", index)
                  }
                />
                <Input
                  defaultValue={item.name}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, "name", index)
                  }
                />
                <Input
                  defaultValue={item.price}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, "price", index)
                  }
                />
                <Input
                  defaultValue={item.telco_price}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, "telcoPrice", index)
                  }
                />
                <Switch
                  checked={item.is_promo}
                  onCheck={() =>
                    handleInputsChange(!item.is_promo, "is_promo", index)
                  }
                />
                <Switch
                  checked={item.disabled}
                  onCheck={() =>
                    handleInputsChange(!item.disabled, "disabled", index)
                  }
                />
                {index !== 0 && (
                  <Close
                    className="cursor-pointer"
                    onClick={() => deleteProvider(index)}
                  />
                )}
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AyinlakProvider;
