import { format } from 'date-fns';
import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../../../components/dropdown';
import { Search } from '../../../components/icons';
import Input from '../../../components/input';
import Pagination from '../../../components/pagination';
import { IUserFilter } from '../../../interface/user';
import { useUsers } from '../../../services/user';
import useUrlPagination from '../../../utils/urlPagination';
import Button from '../../../components/button';

const User = () => {
  const [size, setSize] = useState(10);
  const [filter, setFilter] = useState<IUserFilter>();

  const { currentPage, setPage } = useUrlPagination();

  const { data, isLoading, isPreviousData } = useUsers({
    currentPage,
    size,
    ...filter,
  });

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  const dropdown = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const suspendDropdown = [
    { label: 'Active', value: false },
    { label: 'Inactive', value: true },
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, search: e.target.value });

  const debouncedResults = useMemo(() => {
    return _.debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <h2 className="font-medium text-grayText">
          Users ({data?.data.pagination.total.toLocaleString()})
        </h2>
        <Link to={`/app/user/pending-kyc`}>
          <Button bgColor="bg-activeLinkBlack" className="!text-xs">
            Pending KYC
          </Button>
        </Link>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">Start</span>}
            onChange={(e) =>
              setFilter({ ...filter, startDate: e.target.value.trim() })
            }
          />
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">End</span>}
            onChange={(e) =>
              setFilter({ ...filter, endDate: e.target.value.trim() })
            }
          />
          <Dropdown
            label="BVN"
            option={dropdown}
            onChange={(e) =>
              setFilter({ ...filter, 'verification.bvn': e?.value as boolean })
            }
          />
          <Dropdown
            label="Status"
            option={suspendDropdown}
            onChange={(e) =>
              setFilter({ ...filter, suspend: e?.value as boolean })
            }
          />
        </div>
        <div className=" relative w-full">
          <Input
            type="text"
            placeholder="search"
            leading={<Search />}
            onChange={debouncedResults}
          />
        </div>
      </div>

      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto  tableScrollBar">
          <div className=" inline-block min-w-full ">
            <div className=" overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-lightGray">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Wallet Balance
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Account Number
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Email Address
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Phone Number
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      BVN
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Registered
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                {!isLoading && (
                  <tbody>
                    {data?.data.response.map((item, index) => (
                      <tr key={index} className={'border-b border-grey'}>
                        <td className="text-sm p-4">
                          {item.firstName} {item.lastName}
                        </td>
                        <td className={`text-sm p-4 ${item.wallet > 0 ? "text-green-500" : ""}`}>
                          ₦
                          {item.wallet.toLocaleString(navigator.language, {
                            minimumFractionDigits: 2,
                          })}
                        </td>
                        <td className="text-sm p-4">
                          {item.account[0]?.accountNumber}
                        </td>
                        <td className="text-sm p-4">{item.email}</td>
                        <td className="text-sm p-4">
                          {`${item.dialCode}${item.phone}`}
                        </td>
                        <td className="text-sm p-4">
                          {item.verification?.bvn ? (
                            <div className="text-green-500">Yes</div>
                          ) : (
                            <div className="text-red-500">No</div>
                          )}
                        </td>
                        <td className="text-sm p-4">
                          <div>
                            {format(
                              new Date(item.createdAt),
                              'MMM do, yyyy H:mma'
                            )}
                          </div>
                        </td>
                        <td className="text-sm p-4">
                          {item.suspend ? (
                            <div className="text-red-500">Inactive</div>
                          ) : (
                            <div className="text-green-500">Active</div>
                          )}
                        </td>
                        <td className="text-sm p-4 text-blue">
                          <Link to={`/app/user/${item._id}`}>More details</Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {data?.data.pagination.total! > 0 && (
          <Pagination
            totalItems={data?.data.pagination.total!}
            itemsPerPage={size}
            setPage={setPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </>
  );
};

export default User;
